<template>
  <div class="exhibitCard">
    <a
      :title="`${exhibit.title}(另開新視窗)`"
      :href="exhibit.link"
      target="_blank"
      rel="noreferrer noopener"
    >
      <div class="exhibitCard__body">
        <div class="exhibitCard__main">
          <h3 class="exhibitCard__title" :id="exhibit.id">
            {{ exhibit.title }}
          </h3>
          <div
            :alt="`${exhibit.title}`"
            class="exhibitCard__img"
            :style="`background-image: url(${exhibit.image});`"
          >
            <div class="exhibitCard__introductionImg"></div>
            <!-- <img
              class="exhibitCard__introductionImg"
              src="@/assets/image/Exhibit/Normal/Introduction.png"
              alt="簡介icon"
            /> -->
          </div>
        </div>
        <div class="exhibitCard__desc">
          <p>{{ exhibit.desc }}</p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
      default: 0
    },
    exhibit: {
      type: Object,
      required: true,
      default: () => ({
        mainTitle: '',
        title: '',
        desc: '',
        image: '',
        id: 'gold',
        link: '#'
      })
    }
  }
}
</script>

<style lang="scss">
.exhibitCard {
  margin: 7rem auto;

  &__body {
    display: flex;
  }

  &__main {
    flex-shrink: 0;
    width: calc(100% - 36.6rem);
    margin: 0 auto;
  }

  &__main:hover ~ &__desc,
  &__desc:hover {
    width: 36.6rem;
    display: flex;
    margin-top: 6.5rem;
  }

  &__desc {
    padding: 4rem;
    background: #eee7c8;
    font-size: 2rem;
    display: flex;
    align-items: center;
    width: 0;
    display: none;
    text-align: justify;
    line-height: 140%;
  }

  &__img,
  &__introductionImg {
    padding-bottom: 45%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  &__introductionImg {
    position: absolute;
    right: -1px;
    top: 0;
    width: 5.75rem;
    height: 11.4rem;
    background-image: url('~@/assets/image/Exhibit/Normal/Introduction.png');
    padding-bottom: 0;
  }

  &__title::before {
    content: '';
    background-image: url('~@/assets/image/TitleIcon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 3.8rem;
    height: 2.55rem;
    display: inline-block;
  }

  &__title {
    color: #595757;
    font-weight: 500;
    line-height: 124%;
    letter-spacing: 0.18em;
    font-size: 2.9rem;
    margin-bottom: 3rem;
  }
}

@media (max-width: 768px) {
  .exhibitCard {
    &__main,
    &__desc,
    &__main:hover ~ &__desc,
    &__desc:hover {
      width: 100%;
    }

    &__desc {
      display: block;
    }

    &__body {
      flex-wrap: wrap;
    }

    &__main:hover ~ &__desc,
    &__desc:hover {
      margin-top: 0;
    }
  }
}
</style>

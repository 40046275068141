<template>
  <section class="exhibit__normalCardContainer container">
    <ExhibitCardNormal
      v-for="(exhibit, index) in exhibitLists"
      :key="index"
      :index="index"
      :exhibit="exhibit"
    />
  </section>
</template>

<script>
import ExhibitCardNormal from '@/components/Exhibit/ExhibitCardNormal.vue'

export default {
  components: { ExhibitCardNormal },
  computed: {
    exhibitLists() {
      const exhibit = this.$store.state.exhibit
      if (!exhibit) return []
      const lists = exhibit.find(_exhibit => _exhibit.title === '常設展')
        .exhibit
      return lists
    }
  }
}
</script>

<style lang="scss">
.exhibit__normalCardContainer {
}
</style>
